@import "../assets/globals/Globals.module.css";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  animation: animateBackdrop 300ms ease-out forwards;
}

.modal {
  position: fixed;
  top: 80px;
  height: 85vh;
  left: 5%;
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: animateModal 300ms ease-out forwards;
  overflow: hidden;
}

.buttonLeft {
  position: fixed;
  bottom: 50%;
  left: 2%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.5em;
  color: var(--contrast-color);
}

.buttonRight {
  position: fixed;
  bottom: 50%;
  right: 2%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.5em;
  color: var(--contrast-color);
}

.fa-arrow {
  font-size: 2em;
  transition: transform 0.2s;
}
.fa-arrow:hover {
  cursor: pointer;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .modal {
    width: 80vw;
    left: calc(50% - 40vw);
  }
}

@keyframes animateModal {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animateBackdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
