@import "../assets/globals/Globals.module.css";

.card {
  display: block;
  transition: 0.25s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
}

.card-header {
  height: 150px;
  width: 100%;
  padding: 15px;
  background-size: cover;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card-header--title {
  text-transform: uppercase;
  margin: 15;
}

.card-body {
  padding: 15px;
  background-color: #fff;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.date {
  font-size: 11px;
  color: grey;
}

.title {
  font-family: var(--font-christianheedlay);
}
.body-content {
  padding-bottom: 0px;
  font-size: 13px;
  line-height: 1.8;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: transparent;
  color: blue;
  font-size: 15px;
  transition: 0.25s;
}

.button-primary i {
  padding-right: 4px;
}

.button-primary:hover{
  cursor: pointer;
}
