* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  overscroll-behavior: none;
}
.App {
  background: no-repeat center center fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  min-height: 100vh;
}

/*.pages {
  text-align: justify;
  font-size: 1.5rem;
}*/
