@font-face {
  font-family: "ChristianHeedlay";
  src: local("ChristianHeedlay"),
    url(../fonts/christianheedlay/ChristianHeedlay.woff) format("woff");
}

:root {
  --USA-main-color: rgb(141, 176, 185);
  --USA-main-color-transparent: rgba(141, 176, 185, 0);
  --USA-contrast-color: #8d5b46;
  --font-christianheedlay: "ChristianHeedlay";
  --font-main: Century Gothic;
  --RSA-main-color: rgb(0, 122, 77);
  --RSA-main-color-transparant: rgba(0, 122, 77, 0);
  --RSA-contrast-color: #ffb612;
}
