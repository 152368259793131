@import "../assets/globals/Globals.module.css";

@keyframes textShow {
  from {
    margin-top: 20vh;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0);
  }
  to {
    font-size: 5rem;
  }
}

.homeText {
  font-family: "ChristianHeedlay", Fallback, sans-serif;
  color: white;
  margin: auto;
  margin-top: 10vh;
  text-align: center;
  font-size: 5rem;

  animation-name: textShow;
  animation-duration: 3s;
}
