.Card {
}
@keyframes cardShow {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

.app-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.app-card {
  /*border: 3px solid black;*/
  margin-bottom: 0.5em;
  background-color: white;
  flex: 0 1 24%;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: transform 0.2s;
  animation-name: cardShow;
  animation-duration: 0.5s;
}

.app-card:hover {
  transform: scale(1.02);
  cursor: pointer;
}
