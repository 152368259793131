@import "../assets/globals/Globals.module.css";

.Card {
}
.cardBody {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  margin-bottom: 25px;
  overflow: scroll;
  height: 82vh;
  background-color: rgba(255, 255, 255, 0);
}
.cardDate {
  text-align: center;
}
.cardTitle {
  font-family: var(--font-christianheedlay);
  font-size: 2.5rem;
  margin-bottom: 0;
  text-align: center;
}

.cardText {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: var(--font-main);
  font-size: 1.2rem;
}
.Card img {
  width: 100%;
  height: auto;
  margin: auto;
  display: block;
  margin-bottom: 0.5em;
}
